import React from "react";
import PropTypes from "prop-types";
import "./index.css";

import HeadingOverlay from "../../components/HeadingOverlay";
import InputForm from "../../components/InputForm";

const Contact = ({ isMonitor, isDesktop, isMobile }) => {
  return (
    <div className="contact">
      <HeadingOverlay
        contents={"Contact"}
        isMonitor={isMonitor}
        isDesktop={isDesktop}
      />
      <InputForm isMobile={isMobile} />
    </div>
  );
};

export default Contact;

Contact.propTypes = {
  isMonitor: PropTypes.bool,
  isDesktop: PropTypes.bool,
  isMobile: PropTypes.bool,
};
