import React from "react";
import PropTypes from "prop-types";
import "./index.css";

const SocialIcons = ({ isMonitor, isDesktop, socialMedia }) => {
  const containerClass = isMonitor
    ? "social-icons-container-large"
    : isDesktop
    ? "social-icons-container-desktop"
    : "social-icons-container-mobile";
  const iconId = isMonitor
    ? "social-icon-large"
    : isDesktop
    ? "social-icon-desktop"
    : "social-icon-mobile";

  return (
    <div className={containerClass}>
      {socialMedia.map((x, key) => (
        <a key={key} href={x.link} target="_blank" rel="noopener noreferrer">
          <img
            className="hvr-wobble-horizontal"
            id={iconId}
            src={x.icon}
            alt={`${x.name} logo`}
          />
        </a>
      ))}
    </div>
  );
};

export default SocialIcons;

SocialIcons.propTypes = {
  isMonitor: PropTypes.bool,
  isDesktop: PropTypes.bool,
  socialMedia: PropTypes.array,
};
