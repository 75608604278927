import React from "react";
import PropTypes from "prop-types";
import "./index.css";

import SocialIcons from "../SocialIcons";

const BioText = ({ profile, isDesktop, isMonitor }) => {
  const isMobile = isDesktop || isMonitor ? false : true;

  const containerClass = isMonitor
    ? "text-container"
    : isDesktop
    ? "text-container-desktop"
    : "text-container-mobile";

  const headingClass = isMonitor
    ? "bio-heading-large"
    : isDesktop
    ? "bio-heading-desktop"
    : "bio-heading-mobile";

  const textClass = isMonitor
    ? "bio-text-large"
    : isDesktop
    ? "bio-text-desktop"
    : "bio-text-mobile";
  return (
    <div className={containerClass}>
      <div className={headingClass}>{profile.heading}</div>
      <div className={textClass}>{profile.intro}</div>
      <div className={textClass}>{profile.description}</div>
      <SocialIcons
        isMonitor={isMonitor}
        isDesktop={isDesktop}
        isMobile={isMobile}
        socialMedia={profile.socialMedia}
      />
    </div>
  );
};

export default BioText;

BioText.propTypes = {
  profile: PropTypes.shape({
    name: PropTypes.string,
    heading: PropTypes.string,
    photo: PropTypes.string,
    bitmoji: PropTypes.string,
    intro: PropTypes.string,
    description: PropTypes.string,
    socialMedia: PropTypes.array,
    background: PropTypes.string,
  }),
  isMonitor: PropTypes.bool,
  isDesktop: PropTypes.bool,
};
