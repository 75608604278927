import React from "react";
import PropTypes from "prop-types";

import "./index.css";

import CareerLogo from "../CareerLogo";
import CareerDescription from "../CareerDescription";

import CollapsibleContainer from "../CollapsibleContainer";

const CareerSection = ({ logo, photo, alt, description, url, isMobile }) => {
  const containerClass = isMobile
    ? "career-section-container-mobile"
    : "career-section-container";

  const sectionClass = isMobile ? "career-section-mobile" : "career-section";

  const imgColumnClass = isMobile
    ? "img-column-container-mobile"
    : "img-column-container";

  const columnClass = isMobile
    ? "desc-column-container-mobile"
    : "desc-column-container";

  return (
    <div className={containerClass}>
      <div className={sectionClass}>
        <div className={imgColumnClass}>
          <CareerLogo logo={logo} alt={alt} url={url} isMobile={isMobile} />
        </div>
        <div className={columnClass}>
          {isMobile ? (
            <CollapsibleContainer
              content={
                <CareerDescription contents={description} isMobile={isMobile} />
              }
              openIcon="down"
              closeIcon="up"
            />
          ) : (
            <CareerDescription
              isMobile={isMobile}
              contents={description}
              photo={photo}
              photoDesc={alt}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CareerSection;

CareerSection.propTypes = {
  logo: PropTypes.string,
  photo: PropTypes.string,
  alt: PropTypes.string,
  description: PropTypes.string,
  isMobile: PropTypes.bool,
};
