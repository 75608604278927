import React from "react";
import PropTypes from "prop-types";
import "./index.css";

const ContainerOverlay = ({ child }) => (
  <div className="banner-overlay">{child}</div>
);

export default ContainerOverlay;

ContainerOverlay.propTypes = { child: PropTypes.object };
