import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import "./index.css";

import Project from "../../components/Project";
import ProjectCarousel from "../../components/ProjectCarousel";
import HeadingOverlay from "../../components/HeadingOverlay";

import { Link } from "react-scroll";
import SwipeIcon from "../../icons/swipe.png";

const Projects = ({
  isMonitor,
  isDesktop,
  isMobile,
  projectsList,
  heading,
}) => {
  const [selectedProject, setSelectedProject] = useState(
    !isMobile || projectsList.length < 2 ? projectsList[0] : null
  );
  const [currentIndex, setCurrentIndex] = useState(null);
  const [sameProject, setSameProject] = useState(false);

  const carouselItems = projectsList.map((project, index) => (
    <div
      key={index}
      className={
        selectedProject === project && !sameProject
          ? "inner-container-selected"
          : "inner-container"
      }
    >
      <Link
        className="anchor-link"
        activeClass="active"
        to={heading}
        spy={true}
        smooth={true}
        duration={500}
        onClick={() => {
          if (projectsList.length > 1) {
            setSelectedProject(project);
            if (index === currentIndex && selectedProject) {
              setSameProject(true);
              setSelectedProject(null);
            } else if (!selectedProject) {
              setSameProject(false);
            }
            setCurrentIndex(index);
          }
        }}
      >
        <img
          className={isMobile ? "company-logo-mobile" : "company-logo"}
          src={project.company.logo}
          alt={project.company.name}
        />
      </Link>
    </div>
  ));

  const projectInfo = (project) => {
    const transformedDesc = project.description
      .split("\n")
      .map((item, i) => <p key={i}>{item}</p>);

    const ProjectLink = () => {
      if (project.link.length > 1) {
        return (
          <a
            id="project-link"
            className="hvr-wobble-horizontal-project-title"
            href={project.link}
            rel="noopener noreferrer"
            target="_blank"
          >
            <span className="project-text-link">{project.company.name}</span>
          </a>
        );
      } else {
        return (
          <div
            id="project-link"
            className="hvr-wobble-horizontal-project-title"
          >
            <span className="project-text-link">{project.company.name}</span>
          </div>
        );
      }
    };

    return (
      <div className="project-info-container">
        <Project isMobile={isMobile} project={project} />
        <div className="project-info">
          <span className="project-text">
            "{project.name}" <br />
            {project.year}
          </span>
          <ProjectLink />
          <span className={isMobile ? "project-desc-mobile" : "project-desc"}>
            {transformedDesc}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className="projects">
      <HeadingOverlay
        isMonitor={isMonitor}
        isDesktop={isDesktop}
        contents={heading}
      />
      <Fragment>
        <div
          className={isMobile ? "outer-container-mobile" : "outer-container"}
        >
          <ProjectCarousel contents={carouselItems} />
        </div>
        {selectedProject && !sameProject ? projectInfo(selectedProject) : null}
      </Fragment>
      {!selectedProject && carouselItems.length > 2 ? (
        <img
          className="swipe-icon"
          src={SwipeIcon}
          alt="swipe intertaction logo"
        />
      ) : null}
    </div>
  );
};

export default Projects;

Projects.propTypes = {
  isMonitor: PropTypes.bool,
  isDesktop: PropTypes.bool,
  isMobile: PropTypes.bool,
  projectsList: PropTypes.array,
};
