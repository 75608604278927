import React from "react";
import PropTypes from "prop-types";

import "./index.css";
import BioImage from "../BioImage";
import BioText from "../BioText";

const Bio = ({ isDesktop, isMonitor, profile }) => {
  const bioContainer = isMonitor
    ? "bio-container-large"
    : isDesktop
    ? "bio-container-desktop"
    : "bio-container-mobile";

  const bioContents = isMonitor
    ? "bio-contents-large"
    : isDesktop
    ? "bio-contents-desktop"
    : "bio-contents-mobile";

  return (
    <div className={bioContainer}>
      <div className={bioContents}>
        <BioImage
          profile={profile}
          isDesktop={isDesktop}
          isMonitor={isMonitor}
        />
        <BioText
          profile={profile}
          isDesktop={isDesktop}
          isMonitor={isMonitor}
        />
      </div>
    </div>
  );
};

export default Bio;

Bio.propTypes = {
  isMonitor: PropTypes.bool,
  isDesktop: PropTypes.bool,
  profile: PropTypes.shape({
    name: PropTypes.string,
    heading: PropTypes.string,
    photo: PropTypes.string,
    bitmoji: PropTypes.string,
    intro: PropTypes.string,
    description: PropTypes.string,
    socialMedia: PropTypes.array,
    background: PropTypes.string,
  }),
};
