import React from "react";
import PropTypes from "prop-types";
import "./index.css";

const CareerDescription = ({ isMobile, contents }) => {
  const containerClass = isMobile
    ? "career-description-container-mobile"
    : "career-description-container";

  let transformedContent = contents
    .split("\n")
    .map((item, i) => <p key={i}>{item}</p>);
  return <div className={containerClass}>{transformedContent}</div>;
};

export default CareerDescription;

CareerDescription.propTypes = {
  contents: PropTypes.string,
  isMobile: PropTypes.bool,
};
