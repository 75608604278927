import ANDigitalLogo from "../img/logos/AND Digital_LOGO_SM_icon.jpg";
import ApolloAwardsLogo from "../img/logos/AND_digital_Apollo_Awards.jpeg";
import UberallLogo from "../img/logos/uberall-logo-sm.jpeg";
import UberallHackathonLogo from "../img/logos/uberall-hackathon.jpeg";

const awards = [
  {
    name: "Uberall Hackathon - First Class Journey Award",
    company: { name: "Uberall Hack-AI-Thon", logo: UberallHackathonLogo },
    employer: { name: "Uberall", logo: UberallLogo },
    year: "2023",
    link: "https://www.linkedin.com/posts/mark-a-austin_ai-openai-uberall-activity-7132354464589451267-T3-n?utm_source=share&utm_medium=member_desktop",
    description:
      "Presented with the First Class Journey Award for the Hackathon project with the strongest case for improving customer experience. \n The winning implementation is described in more detail in the projects section.",
    technologies: [],
  },
  {
    name: "Uberall - Best Documenter in Engineering Award",
    company: { name: "Uberall", logo: UberallLogo },
    employer: { name: "Uberall", logo: UberallLogo },
    year: "2023",
    link: "https://uberall.com/en-us",
    description:
      "Presented the 'Best Documenter' award at the engineering offsite in Berlin. This was awarded for writing clean code, comprehensive documentation for complex features, documentation for domain business logic and onboarding guides for new engineers.",
    technologies: [],
  },
  {
    name: "AND Digital - Delight Award",
    company: { name: "AND Digital", logo: ANDigitalLogo },
    employer: { name: "AND Digital", logo: ANDigitalLogo },
    year: "2022",
    link: "https://www.and.digital/",
    description:
      "Awarded the AND Digital Delight Award for my work with my current client. \n\n 'Mark makes a huge impact on SBG. Mark is always willing to help others, and ensures that delivery is at the highest standard. Recently Mark delighted the client by his excellent work on a challenging task which required his strong technical skills and knowledge to resolve.'",
    technologies: [],
  },
  {
    name: "AND Digital - Goddard Fusion Award",
    company: { name: "AND Digital", logo: ApolloAwardsLogo },
    employer: { name: "AND Digital", logo: ANDigitalLogo },
    year: "2022",
    link: "https://www.and.digital/",
    description:
      "Awarded the AND Digital Goddard Fusion Team Award for being part of a team that has effectively demonstrated all five Cs of the Apollo Awards: content, collaboration, courage, consistency and context. \n\n I have been part of the core team that has worked with the client to deliver multiple successful projects over 2 years. I have demonstrated deep technical expertise to enable the design and delivery of new architectures and complex solutions to unique business problems, from the ground up. I have led and influenced architectural decisions in collaboration with key senior client architects and engineers.",
    technologies: [],
  },
  {
    name: "AND Digital - Courage Award Nomination",
    company: { name: "AND Digital", logo: ApolloAwardsLogo },
    employer: { name: "AND Digital", logo: ANDigitalLogo },
    year: "2022",
    link: "https://www.and.digital/",
    description:
      "Nominated for the AND Digital Johnson Award for Courage. I was shortlisted for this award for being a brilliant team player and a natural leader. It was noted that I really care about the quality of work produced by the team and that I look out for other members of the team.",
    technologies: [],
  },
  {
    name: "AND Digital - Delight Award",
    company: { name: "AND Digital", logo: ANDigitalLogo },
    employer: { name: "AND Digital", logo: ANDigitalLogo },
    year: "2021",
    link: "https://www.and.digital/",
    description:
      "Awarded the AND Digital Delight Award for my work with my current client. This was awarded for going above and beyond to help other engineers on the team whilst delivering and meeting client deadlines and expectations. \n\n I always ensure that I am mentoring and helping other engineers on the team so we have a shared understanding of the complex buisness domain and a shared direction for the project.",
    technologies: [],
  },
];

export default awards;
