import React from "react";
import PropTypes from "prop-types";
import "./index.css";

const InputArea = ({
  isMobile,
  name,
  type,
  placeholder,
  value,
  required,
  onChange,
}) => (
  <textarea
    className={isMobile ? "input-area-mobile" : "input-area"}
    name={name}
    type={type}
    placeholder={placeholder}
    value={value}
    required={required}
    onChange={onChange}
  />
);

export default InputArea;

InputArea.propTypes = {
  isMobile: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
};
