import React, { Fragment } from "react";
import Proptypes from "prop-types";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const ProjectCarousel = ({ contents }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 6,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };
  return (
    <Fragment>
      <Carousel
        className="project-carousel"
        swipeable={true}
        draggable={true}
        showDots={false}
        responsive={responsive}
        ssr={true}
        infinite={true}
        autoPlay={false}
        keyBoardControl={false}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        deviceType={null}
        itemClass="carousel-item-padding-40-px"
      >
        {contents}
      </Carousel>
    </Fragment>
  );
};

export default ProjectCarousel;

ProjectCarousel.propTypes = { contents: Proptypes.array };
