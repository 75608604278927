import React from "react";
import PropTypes from "prop-types";

import "./index.css";

const headingOverlay = ({ isMonitor, isDesktop, contents }) => {
  const containerClass = isMonitor
    ? "heading-container-large"
    : isDesktop
    ? "heading-container-desktop"
    : "heading-container-mobile";
  const headingTextClass = isMonitor
    ? "heading-text-large"
    : isDesktop
    ? "heading-text-desktop"
    : "heading-text-mobile";

  const underlineClass = isMonitor
    ? "heading-underline-large"
    : isDesktop
    ? "heading-underline-desktop"
    : "heading-underline-mobile";

  return (
    <div className={containerClass}>
      <div className="overlay">
        <div className={headingTextClass}>{contents}</div>
        <span className={underlineClass} />
      </div>
    </div>
  );
};

export default headingOverlay;

headingOverlay.propTypes = {
  isMonitor: PropTypes.bool,
  isDesktop: PropTypes.bool,
  contents: PropTypes.string,
};
