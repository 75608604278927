import React from "react";
import PropTypes from "prop-types";
import "./index.css";

const BioImage = ({ profile, isDesktop, isMonitor }) => {
  const containerClass = isMonitor
    ? "bio-image-container"
    : isDesktop
    ? "bio-image-container-desktop"
    : "bio-image-container-mobile";

  const imageClass = isMonitor
    ? "bio-image-large"
    : isDesktop
    ? "bio-image-desktop"
    : "bio-image-mobile";
  return (
    <div className={containerClass}>
      <img className={imageClass} src={profile.photo} alt={profile.name} />
    </div>
  );
};

export default BioImage;

BioImage.propTypes = {
  profile: PropTypes.shape({
    name: PropTypes.string,
    heading: PropTypes.string,
    photo: PropTypes.string,
    bitmoji: PropTypes.string,
    intro: PropTypes.string,
    description: PropTypes.string,
    socialMedia: PropTypes.array,
    background: PropTypes.string,
  }),
  isDesktop: PropTypes.bool,
  isMonitor: PropTypes.bool,
};
