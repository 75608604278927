import React from "react";
import PropTypes from "prop-types";

import "./index.css";

import HeadingOverlay from "../../components/HeadingOverlay";
import SkillsGrid from "../../components/SkillsGrid";

const Skills = ({ isMonitor, isDesktop, skillsList }) => {
  return (
    <div className="skills">
      <HeadingOverlay
        isMonitor={isMonitor}
        contents={"Tech & Tools"}
        isDesktop={isDesktop}
      />
      <SkillsGrid
        skills={skillsList}
        isDesktop={isDesktop}
        isMonitor={isMonitor}
      />
    </div>
  );
};

export default Skills;

Skills.propTypes = {
  isMonitor: PropTypes.bool,
  isDesktop: PropTypes.bool,
  skillsList: PropTypes.array,
};
