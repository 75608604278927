import React from "react";
import PropTypes from "prop-types";
import "./index.css";

const BackgroundImage = ({ isMonitor, isDesktop, img }) => {
  const containerClass = isMonitor
    ? "banner-container"
    : isDesktop
    ? "banner-container-desktop"
    : "banner-container-mobile";
  return (
    <div className={containerClass}>
      <img id="banner-img" src={img} alt="custom-background" />
    </div>
  );
};

export default BackgroundImage;

BackgroundImage.propTypes = {
  isMonitor: PropTypes.bool,
  isDesktop: PropTypes.bool,
  img: PropTypes.string,
};
