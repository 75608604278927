import JavaScriptLogo from "../img/logos/javascript-logo.png";
import ReactLogo from "../img/logos/react-logo.png";
import HTML5Logo from "../img/logos/html5-logo.png";
import CSSLogo from "../img/logos/css-logo.png";
import JavaLogo from "../img/logos/java-logo.png";
import SpringbootLogo from "../img/logos/springboot-logo.png";
import NodeLogo from "../img/logos/nodejs-logo.png";
import AWSLogo from "../img/logos/aws-logo.png";
import MongoLogo from "../img/logos/mongo-logo.png";
import GradleLogo from "../img/logos/gradle-logo.png";
import WordpressLogo from "../img/logos/wordpress-logo.png";
import OracleSQLLogo from "../img/logos/oracle-sql.png";
import KotlinLogo from "../img/logos/kotlin-logo.png";
import AtlassianLogo from "../img/logos/atlassian-logo.png";
import GitLogo from "../img/logos/git-logo.png";
import GithubLogo from "../img/logos/github-logo.png";
import GitlabLogo from "../img/logos/gitlab-logo.png";
import PSMLogo from "../img/logos/psm1-logo.png";
import GrailsLogo from "../img/logos/grails-logo.png";
import KubernetesLogo from "../img/logos/kubernetes-logo.png";
import DockerLogo from "../img/logos/docker-logo.png";
import PostgresqlLogo from "../img/logos/postgresql.png";
import NextJSLogo from "../img/logos/next-js-logo.png";
import ElasticSearchLogo from "../img/logos/elasticsearch-logo.png";
import RabbitLogo from "../img/logos/rabbitmq-logo.png";
import GrafanaLogo from "../img/logos/grafana-logo.png";
import PrometheusLogo from "../img/logos/Prometheus_software_logo.png";

const skills = [
  { name: "Kotlin", logo: KotlinLogo },
  { name: "SpringBoot", logo: SpringbootLogo },
  { name: "Gradle", logo: GradleLogo },
  { name: "RabbitMQ", logo: RabbitLogo },
  { name: "Prometheus", logo: PrometheusLogo },
  { name: "Grafana", logo: GrafanaLogo },
  { name: "Grails", logo: GrailsLogo },
  { name: "ElasticSearch", logo: ElasticSearchLogo },
  { name: "MySQL", logo: OracleSQLLogo },
  { name: "PostgreSQL", logo: PostgresqlLogo },
  { name: "AWS", logo: AWSLogo },
  { name: "Kubernetes", logo: KubernetesLogo },
  { name: "Docker", logo: DockerLogo },
  { name: "Java", logo: JavaLogo },
  { name: "NextJS", logo: NextJSLogo },
  { name: "React", logo: ReactLogo },
  { name: "JavaScript", logo: JavaScriptLogo },
  { name: "HTML5", logo: HTML5Logo },
  { name: "CSS", logo: CSSLogo },
  { name: "NodeJS", logo: NodeLogo },
  { name: "MongoDB", logo: MongoLogo },
  { name: "WordPress", logo: WordpressLogo },
  { name: "Atlassian Stack", logo: AtlassianLogo },
  { name: "Scrum Master (PSM1 certified)", logo: PSMLogo },
  { name: "KuberGitnetes", logo: GitLogo },
  { name: "Github", logo: GithubLogo },
  { name: "Gitlab", logo: GitlabLogo },
];

export default skills;
