import React, { useState } from "react";
import PropTypes from "prop-types";
import "./index.css";

import SocialIcons from "../SocialIcons";
import BurgerMenuIcon from "../../icons/burger-menu.svg";
import CloseIcon from "../../icons/close.svg";
import BitmojiIcon from "../../img/profile/bitmoji.svg";
import { stack as Menu } from "react-burger-menu";
import { Link } from "react-scroll";

const MobileNav = ({ socialMedia, preventScroll }) => {
  const [menuState, setMenuState] = useState(false);

  const closeMenu = () => {
    setMenuState(false);
    preventScroll(false);
  };
  return (
    <div>
      <img
        className="burger-menu-icon"
        src={BurgerMenuIcon}
        alt="burger-menu-icon"
        onClick={() => {
          setMenuState(true);
          preventScroll(true);
        }}
      />

      <Menu
        isOpen={menuState}
        customBurgerIcon={false}
        customCrossIcon={false}
        disableOverlayClick
      >
        <img
          className="bitmoji-icon"
          src={BitmojiIcon}
          alt="Mark Austin logo"
        />
        <img
          className="close-icon"
          src={CloseIcon}
          alt="close-icon"
          onClick={() => closeMenu()}
        />
        <Link
          className="anchor-link"
          activeClass="active"
          to="about"
          spy={true}
          smooth={true}
          duration={500}
          onClick={() => closeMenu()}
        >
          About
        </Link>
        <Link
          className="anchor-link"
          activeClass="active"
          to="career"
          spy={true}
          smooth={true}
          duration={500}
          onClick={() => closeMenu()}
        >
          Career
        </Link>
        <Link
          className="anchor-link"
          activeClass="active"
          to="projects"
          spy={true}
          smooth={true}
          duration={500}
          onClick={() => closeMenu()}
        >
          Projects
        </Link>
        <Link
          className="anchor-link"
          activeClass="active"
          to="awards"
          spy={true}
          smooth={true}
          duration={500}
          onClick={() => closeMenu()}
        >
          Awards
        </Link>
        <Link
          className="anchor-link"
          activeClass="active"
          to="volunteering"
          spy={true}
          smooth={true}
          duration={500}
          onClick={() => closeMenu()}
        >
          Volunteering
        </Link>
        <Link
          className="anchor-link"
          activeClass="active"
          to="contact"
          spy={true}
          smooth={true}
          duration={500}
          onClick={() => closeMenu()}
        >
          Contact
        </Link>
        <div className="menu-item">
          <SocialIcons socialMedia={socialMedia} />
        </div>
      </Menu>
    </div>
  );
};

export default MobileNav;

MobileNav.propTypes = {
  socialMedia: PropTypes.array,
  preventScroll: PropTypes.func,
};
