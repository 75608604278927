import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./index.css";

import { Button } from "@material-ui/core";
import InputLabel from "../InputLabel";
import InputText from "../InputText";
import InputArea from "../InputArea";

import axios from "axios";

const InputForm = ({ isMobile }) => {
  const date = new Date();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    date: date.toLocaleString(),
    year: date.getFullYear(),
    message: "",
  });
  const [serverMessage, setServerMessage] = useState(null);
  const [buttonText, setButtonText] = useState("Submit");
  const [submitted, setSubmitted] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState(true);
  const [disableButton, setDisableButton] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);

  const onSubmit = (e) => {
    var emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    e.preventDefault();
    setSubmitted(true);

    if (emailRegex.test(formData.email)) {
      setEmailInvalid(false);
      axios
        .post(process.env.REACT_APP_API_ENDPOINT, { ...formData })
        .then((res) => {
          setSubmitted(false);
          setEmailSuccess(res.data.success);
          setServerMessage(res.data.message);
          if (res.data.success) {
            setFormData({ name: "", email: "", message: "" });
          }
        })
        .catch((e) => {
          setSubmitted(false);
          setEmailSuccess(false);
          console.error(e);
          setServerMessage("There has been an error. Please try again.");
        });
    } else {
      setEmailInvalid(true);
      e.preventDefault();
      setSubmitted(false);
      setEmailSuccess(false);
      setServerMessage("Please enter a valid email address and try again.");
    }
  };

  useEffect(() => {
    if (emailSuccess && !submitted) {
      setDisableButton(false);
      setButtonText("Submit");
    } else if (!emailSuccess && !submitted) {
      setDisableButton(false);
      setButtonText("Try again");
    } else if (submitted) {
      setDisableButton(true);
      setServerMessage("sending...");
      setButtonText("");
    }
  }, [emailSuccess, submitted]);

  const onChange = (e) => {
    const copyData = { ...formData };
    copyData[e.target.name.toLowerCase()] = e.target.value;
    setFormData(copyData);
  };

  const sectionClass = isMobile
    ? "form-section-container-mobile"
    : "form-section-container";
  return (
    <div
      className={
        isMobile ? "contact-form-container-mobile" : "contact-form-container"
      }
    >
      <form
        className={isMobile ? "contact-form-mobile" : "contact-form"}
        onSubmit={onSubmit}
      >
        <div className={sectionClass}>
          <InputLabel isMobile={isMobile} value="Name" />
          <InputText
            isMobile={isMobile}
            name="Name"
            type="text"
            placeholder="Name"
            value={formData.name}
            required
            onChange={onChange}
          />
        </div>
        <div className={sectionClass}>
          <InputLabel isMobile={isMobile} value="Email" />
          <InputText
            isMobile={isMobile}
            name="Email"
            type="text"
            placeholder="Email"
            value={formData.email}
            required
            onChange={onChange}
            isEmailInvalid={emailInvalid}
          />
        </div>
        <div className={sectionClass}>
          <InputLabel isMobile={isMobile} value="Message" />
          <InputArea
            isMobile={isMobile}
            name="Message"
            type="text"
            placeholder="Message"
            value={formData.message}
            required
            onChange={onChange}
          />
        </div>
        <div className="server-feedback-text">{serverMessage}</div>
        <div className="input-button-container">
          {disableButton ? (
            <div className="spinner">
              <div className="dot1"></div>
              <div className="dot2"></div>
            </div>
          ) : (
            <Button id="input-button" type="submit" variant="contained">
              {buttonText}
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

export default InputForm;

InputForm.propTypes = { isMobile: PropTypes.bool };
