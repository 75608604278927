import React, { Fragment } from "react";
import PropTypes from "prop-types";
import "./index.css";

const Project = ({ isMobile, project }) => {
  return (
    <div
      className={!isMobile ? "project-container" : "project-container-mobile"}
    >
      <div
        className={
          isMobile ? "tech-stack-container-mobile" : "tech-stack-container"
        }
      >
        {project.technologies.map((x, key) => {
          return (
            <Fragment key={key}>
                <Fragment>
                  <img
                    className={!isMobile ? "tech-item" : "tech-item-mobile"}
                    src={x.logo}
                    alt={x.name}
                    title={x.name}
                  />
                </Fragment>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default Project;

Project.propTypes = { isMobile: PropTypes.bool, project: PropTypes.object };
