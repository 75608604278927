import React from "react";
import PropTypes from "prop-types";
import "./index.css";

const InputLabel = ({ isMobile, value }) => (
  <label
    className={isMobile ? "input-label-mobile" : "input-label"}
    htmlFor="message-input"
  >
    {value}:
  </label>
);

export default InputLabel;

InputLabel.propTypes = { isMobile: PropTypes.bool, value: PropTypes.string };
