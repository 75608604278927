import React, { Fragment } from "react";
import PropTypes from "prop-types";

import "./index.css";

import HeadingOverlay from "../../components/HeadingOverlay";
import CareerSection from "../../components/CareerSection";

const Career = ({ experienceList, isMonitor, isDesktop, isMobile }) => {
  return (
    <div className="career">
      <HeadingOverlay
        isMonitor={isMonitor}
        isDesktop={isDesktop}
        contents={"Career"}
      />
      {experienceList.map((x, key) => (
        <Fragment key={key}>
          <CareerSection
            logo={x.logo}
            photo={x.photo}
            alt={x.name}
            description={x.description}
            url={x.url}
            isMobile={isMobile}
          />
        </Fragment>
      ))}
      ;
    </div>
  );
};

export default Career;

Career.propTypes = {
  experienceList: PropTypes.array,
  isMonitor: PropTypes.bool,
  isDesktop: PropTypes.bool,
  isMobile: PropTypes.bool,
};
