import React from "react";
import PropTypes from "prop-types";
import "./index.css";

const NavLink = ({ title, isMonitor }) => {
  const buttonId = isMonitor ? "nav-button-large" : "nav-button";

  return (
    <div className="nav-button-container">
      <span className="hvr-wobble-horizontal" id={buttonId}>
        {title.toUpperCase()}
      </span>
    </div>
  );
};

export default NavLink;

NavLink.propTypes = {
  title: PropTypes.string,
  isMonitor: PropTypes.bool,
};
