import CFGLogo from "../img/logos/code-first-girls-logo.png";
import SlungLowLogo from "../img/logos/slung-low-logo.png";
import LbuLogo from "../img/logos/lbu-logo.jpeg";
import AlexaLogo from "../img/logos/alexa-logo.png";
import VoiceFlowLogo from "../img/logos/Voiceflow-logo.png";
import JavaScriptLogo from "../img/logos/javascript-logo.png";
import HTML5Logo from "../img/logos/html5-logo.png";
import CSSLogo from "../img/logos/css-logo.png";
import ANDLogo from "../img/logos/AND Digital_LOGO_SM_icon.jpg";

const volunteering = [
  {
    name: "Career Coach",
    company: { name: "AND Digital", logo: ANDLogo },
    employer: { name: "Mark Austin", logo: ANDLogo },
    year: "2020 - 2023",
    link: "https://www.and.digital/",
    description:
      "During my time at AND Digital I volunteered to be a career coach. \n This involved fortnightly meetings with junior engineers to help shape their career path and map out clear, acheivable goals by following the GROW model. \n  ",
    technologies: [],
  },
  {
    name: "Design Sprint in an Hour",
    company: { name: "Leeds Beckett University", logo: LbuLogo },
    employer: { name: "Mark Austin", logo: LbuLogo },
    year: "2021",
    link: "https://www.leedsbeckett.ac.uk/",
    description:
      "Leeds Beckett University ran a programme called DigitalLEAP, an initiative that aims to showcase digital careers to graduates from non-tech backgrounds. \n As part of this initiative, my colleagues and I from AND Digital organised and presented a 'Design Sprint in an Hour' workshop. The workshop was held remotely via Zoom due to the COVID Pandemic. The workshop introduced the theory of design sprints and allowed students to participate in their very own design sprint.",
    technologies: [],
  },
  {
    name: "'An Introduction to Coding'",
    company: { name: "Slung Low", logo: SlungLowLogo },
    employer: { name: "Mark Austin", logo: SlungLowLogo },
    year: "2021",
    link: "https://www.slunglow.org/",
    description:
      "Slung Low are a community college and theatre company that are based in the U.K's oldest working men's club in Holbeck, Leeds. \n To help residents in the Leeds area get involved in tech and the software industry, I planned and organised 2 workshops that covered the basics of coding. The workshops explored the fundamentals of HTML and CSS. At the end of the workshops, participants had their own portfolio websites which they had developed themselves. Each workshop consisted of delivering the theory behind web development, alongside coding exercises that helped participants slowly build up their websites.  \n Both workshops were completed remotely due to the COVID pandemic with the help of my colleagues from AND Digital. CodeSandbox was used as an online editor which enabled instructors to help students remotely. ",
    technologies: [
      { name: "HTML5", logo: HTML5Logo },
      { name: "CSS", logo: CSSLogo },
    ],
  },
  {
    name: "Alexa Skills Workshop",
    company: { name: "Leeds Beckett University", logo: LbuLogo },
    employer: { name: "Mark Austin", logo: LbuLogo },
    year: "2020",
    link: "https://www.leedsbeckett.ac.uk/",
    description:
      "Leeds Beckett University run a programme called DigitalLEAP, an initiative that aims to showcase digital careers to graduates from non-tech backgrounds. \n As part of this initiative, my colleagues and I from AND Digital ran an 'Alexa Skills' workshop. The workshop was held at the Leeds Beckett University campus and was attended by 30 students. \n The workshop introduced students to Alexa, the technology that enables skills to work and the opportunity for students to create their very own skill by using VoiceFlow.com. The students then deployed their skills to an Alexa and demoed the skills they had built to the workshop.",
    technologies: [
      { name: "Alexa", logo: AlexaLogo },
      { name: "VoiceFlow", logo: VoiceFlowLogo },
    ],
  },
  {
    name: "An Introduction to Web Development",
    company: { name: "Code First Girls", logo: CFGLogo },
    employer: { name: "Mark Austin", logo: CFGLogo },
    year: "2019 - 2020",
    link: "https://codefirstgirls.org.uk/",
    description:
      "Code First Girls is a Not for Profit Social Enterprise that trains women in IT skills and helps companies to develop more female‑friendly recruitment policies. \n I volunteered as an instructor for the Code First: Girls courses that were held at AND Digital. The courses were 8 week programmes that ran every few months on an evening which aimed to teach attendees the fundamentals of modern web development (JavaScript, HTML, CSS and version control). The material was delivered via presentations, live coding demonstrations and weekly exercises.",
    technologies: [
      { name: "Javascript", logo: JavaScriptLogo },
      { name: "HTML5", logo: HTML5Logo },
      { name: "CSS", logo: CSSLogo },
    ],
  },
];

export default volunteering;
