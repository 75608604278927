import ANDLogo from "../img/logos/and-digital-logo.png";
import BHGELogo from "../img/logos/bhge-logo.png";
import HallamLogo from "../img/logos/sheffield-hallam-logo.png";
import UberallLogo from "../img/logos/uberall-purple-logo.svg";

import ANDPhoto from "../img/profile/and-digital-pro.jpg";

const descriptionUberall =
  "As a Senior Backend Engineer on the Analytics & Reporting team at Uberall, I play a pivotal role in orchestrating integrations with third-party APIs, including those from Apple, Facebook and Yelp. These integrations empower Uberall customers with real-time data analytics and insights, enhancing their experience and decision-making processes.\nIn addition to integration work, I prioritise the resolution of time-sensitive, business-critical bugs, ensuring smooth operation and reliability of our platform. Furthermore, I actively promote agile processes and development methodologies within our team, fostering an environment of efficiency and collaboration.";
const descriptionAND =
  "During my tenure at AND Digital spanning over four years, I held the position of Senior Product Developer. Throughout this period, I collaborated with a diverse array of clients across the UK, spanning industries such as Betting & Gaming, Telecommunications, Retail, and Charity.\nMy primary focus was on developing scalable, maintainable, and high-performing applications, adhering meticulously to industry standards and best practices. This involved leveraging a variety of technologies and techniques to deliver robust solutions that met the unique needs and challenges of each client.\nI had the opportunity to work on both front-end and back-end applications, each of which was successfully deployed into production environments. By combining technical expertise with a deep understanding of client requirements, I contributed to the successful delivery of impactful solutions that added value to businesses across various sectors.";
const descriptionBHGE =
  "During my industrial placement year at university, I had the privilege of working at Baker Hughes, a GE company, as a Software Intern.\nWithin the IT team, my primary focus was on developing data visualisations aimed at optimising the performance and efficiency of machines in the pipeline factory. This involved leveraging my skills to create insightful and user-friendly visual representations of key data metrics, contributing to informed decision-making and process improvements.\nAdditionally, I had the opportunity to contribute to the development of a web application for the recruitment team. This application served as a showcase for GE at recruitment events, highlighting the company's culture, values, and career opportunities. Throughout this project, we adhered to agile principles and employed the Scrum methodology to effectively manage tasks and meet stringent deadlines.";
const descriptionHallam =
  "I achieved a First Class BSc Honours degree in Computer Science from Sheffield Hallam University. Throughout my academic journey, I acquired proficiency in a wide range of programming languages and delved into industry-standard software development techniques, methodologies, and frameworks. \nAttending university provided me with a solid foundation in computer science principles and equipped me with practical skills essential for software development in today's dynamic industry landscape. I am confident in my ability to apply these acquired skills and knowledge to tackle complex challenges and drive innovation in real-world scenarios.";

const experience = [
  {
    name: "Uberall",
    logo: UberallLogo,
    photo: "",
    description: descriptionUberall,
    url: "https://uberall.com/en-gb",
  },
  {
    name: "AND Digital",
    logo: ANDLogo,
    photo: ANDPhoto,
    description: descriptionAND,
    url: "https://and.digital/",
  },
  {
    name: "Baker Hughes, a GE company",
    logo: BHGELogo,
    photo: "",
    description: descriptionBHGE,
    url: "https://www.bakerhughes.com/",
  },
  {
    name: "Sheffield Hallam University",
    logo: HallamLogo,
    photo: "",
    description: descriptionHallam,
    url: "https://www.shu.ac.uk/",
  },
];

export default experience;
