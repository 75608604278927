import JavaScriptLogo from "../img/logos/javascript-logo.png";
import ReactLogo from "../img/logos/react-logo.png";
import HTML5Logo from "../img/logos/html5-logo.png";
import CSSLogo from "../img/logos/css-logo.png";
import JavaLogo from "../img/logos/java-logo.png";
import SpringbootLogo from "../img/logos/springboot-logo.png";
import NodeLogo from "../img/logos/nodejs-logo.png";
import MongoLogo from "../img/logos/mongo-logo.png";
import PythonLogo from "../img/logos/python-logo.png";
import WordpressLogo from "../img/logos/wordpress-logo.png";
import MySQLLogo from "../img/logos/mysql-logo.png";
import DockerLogo from "../img/logos/docker-logo.png";
import MicrobitLogo from "../img/logos/Microbit.png";
import MavenLogo from "../img/logos/maven.png";
import AmplienceLogo from "../img/logos/amplience.png";
import TableauLogo from "../img/logos/tableau.png";
import OracleSQL from "../img/logos/oracle-sql.png";
import CypressLogo from "../img/logos/cypress-logo.png";
import MSWLogo from "../img/logos/msw-logo.png";
import KotlinLogo from "../img/logos/kotlin-logo.png";
import SpringBootLogo from "../img/logos/springboot-logo.png";
import KafkaLogo from "../img/logos/kafka-logo.png";
import KubernetesLogo from "../img/logos/kubernetes-logo.png";
import JenkinsLogo from "../img/logos/jenkins-logo.png";
import GradleLogo from "../img/logos/gradle-logo.png";
import AWSLogo from "../img/logos/aws-logo.png";
import ReactNativeLogo from "../img/logos/react-native-logo.png";
import NextJsLogo from "../img/logos/next-js-logo.png";
import StorybookLogo from "../img/logos/storybook-logo.png";
import JestLogo from "../img/logos/jest-logo.png";
import MicronautLogo from "../img/logos/micronaut-logo.png";
import GrailsLogo from "../img/logos/grails-logo.png";
import JUnit5Logo from "../img/logos/junit5-logo.png";
import MockitoLogo from "../img/logos/mockito.png";
import SQLLogo from "../img/logos/sql.png";
import PostgreSQLLogo from "../img/logos/postgresql.png";
import LangChain4JLogo from "../img/logos/langchain4j-logo.png";
import VercelLogo from "../img/logos/vercel-logo.png";
import ReduxLogo from "../img/logos/redux-logo.png";
import RabbitMQLogo from "../img/logos/rabbitmq-logo.png";
import GrafanaLogo from "../img/logos/grafana-logo.png";
import PrometheusLogo from "../img/logos/Prometheus_software_logo.png";
import PeriscopeLogo from "../img/logos/periscope-logo.png";
import ElasticsearchLogo from "../img/logos/elasticsearch-logo.png";
import RedisLogo from "../img/logos/redis-logo.png";
import NextJSLogo from "../img/logos/next-js-logo.png";
import PSMLogo from "../img/logos/psm1-logo.png";
import ScrumLogo from "../img/logos/scrum-logo.png";
import AtlassianLogo from "../img/logos/atlassian-logo.png";
import LucidChartLogo from "../img/logos/lucidchart-logo.png";
import OpenAILogo from "../img/logos/openai-chatgpt-logo.png";

import ANDLogo from "../img/logos/and-digital-logo.png";
import MKMLogo from "../img/logos/mkm.png";
import KCOMLogo from "../img/logos/kcom.png";
import QueensgateGlassLogo from "../img/logos/queensgate-glass.png";
import SelfEmployedLogo from "../img/profile/and-digital-pro.jpg";
import TheMensRoomLogo from "../img/logos/mensroom.png";
import HallamLogo from "../img/logos/sheffield-hallam-square.png";
import BakerHughesLogo from "../img/logos/bakerhughes.png";
import NBrownLogo from "../img/logos/nbrown-logo.gif";
import SBGLogo from "../img/logos/sbg-logo.png";
import UberallLogo from "../img/logos/uberall-logo-sm.jpeg";
import AustinWeddingLogo from "../img/logos/austinWedding.png";
import UberallHackathonLogo from "../img/logos/uberall-hackathon.jpeg";
import IndustrialDoorsLogo from "../img/logos/industrial_doors_logo.png";

const projects = [
  {
    name: "Industrial Doors SW - Company Website",
    company: { name: "Industrial Doors SW", logo: IndustrialDoorsLogo },
    employer: { name: "Mark Austin", logo: IndustrialDoorsLogo },
    year: "March 2024",
    link: "https://www.industrialdoorssw.co.uk/",
    description:
      "Designed, implemented and deployed an SSR Next JS Web application for Industrial Doors SW. The website is fully responsive and features an image carousel to showcase recent projects and a fully functional contact form that sends all forms to the Industrial Doors SW Gmail account. \n Deployment is handled automatically via a CI/CD pipeline I created using Vercel.",
    technologies: [
      { name: "NextJS", logo: NextJSLogo },
      { name: "React", logo: ReactLogo },
      { name: "Javascript", logo: JavaScriptLogo },
      { name: "HTML5", logo: HTML5Logo },
      { name: "CSS", logo: CSSLogo },
      { name: "Vercel", logo: VercelLogo },
    ],
  },
  {
    name: "Apple Insights Integration",
    company: { name: "Uberall", logo: UberallLogo },
    employer: { name: "Uberall", logo: UberallLogo },
    year: "January 2024 - Present",
    link: "https://uberall.com/en-gb/platform-overview",
    description:
      "As the tech lead, I had the exciting opportunity to lead the development of a project to integrate Apple Insights into the Uberall Platform. This involved a deep dive into the Apple Business Connect API, thoroughly understanding and documenting it while liaising with Apple throughout the design and development phases. I also took charge of designing and architecting a new system, complete with detailed diagrams for architecture, sequence, class, and flow.\nI presented the design proposal to the Uberall Engineering directors to gain approval to move ahead with the project. To ensure the project stayed within budget, I broke the key milestones down into small, manageable tasks for the team, ensuring a smooth and efficient implementation process. I set up the necessary architecture and infrastructure and rolled out monitoring, alerting, and performance dashboards.\nThe project was a huge success, making us the first in the SaaS industry to integrate with Apple Insights.",
    technologies: [
      { name: "Kotlin", logo: KotlinLogo },
      { name: "Micronaut", logo: MicronautLogo },
      { name: "AWS", logo: AWSLogo },
      { name: "Kubernetes", logo: KubernetesLogo },
      { name: "PostgreSQL", logo: PostgreSQLLogo },
      { name: "Grafana", logo: GrafanaLogo },
      { name: "PrometheusLogo", logo: PrometheusLogo },
    ],
  },
  {
    name: "AIfred - Hack-AI-Thon",
    company: { name: "Uberall", logo: UberallHackathonLogo },
    employer: { name: "Uberall", logo: UberallLogo },
    year: "November 2023",
    link: "https://www.linkedin.com/posts/mark-a-austin_ai-openai-uberall-activity-7132354464589451267-T3-n?utm_source=share&utm_medium=member_desktop",
    description:
      "I had the privilege of participating in the 2023 Uberall Hackathon, dubbed the 'Hack-AI-Thon'. As the lead backend engineer on a team of four, we embarked on a 48-hour journey to conceive a solution aimed at enhancing the Uberall business and platform.\nOur team opted to develop an application geared towards elevating the customer experience by leveraging OpenAI through the LangChain4J library. Following an intense 48-hour sprint, our brainchild, 'AIfred', came to fruition. 'AIfred' serves as a customer support accelerator, meticulously crafted using FlutterFlow, Kotlin, Micronaut, and LangChain4J. This innovative tool integrates seamlessly with various data providers, empowering the Uberall Customer Success team with an AI assistant capable of fielding client queries and delivering real-time responses based on insights gleaned from Uberall's knowledge base.\nConcluding the Hack-AI-Thon, I had the honor of presenting the technical intricacies of our solution to the Uberall Leadership Team. During this presentation, I elucidated our use case, showcased examples of 'AIfred' in action, and provided a comprehensive dive into the underlying technology powering our groundbreaking solution.",
    technologies: [
      { name: "Kotlin", logo: KotlinLogo },
      { name: "Micronaut", logo: MicronautLogo },
      { name: "LangChain4J", logo: LangChain4JLogo },
      { name: "OpenAI", logo: OpenAILogo },
      { name: "Redis", logo: RedisLogo },
      { name: "Docker", logo: KubernetesLogo },
    ],
  },
  {
    name: "Wedding Website",
    company: { name: "Mark Austin", logo: AustinWeddingLogo },
    employer: { name: "Mark Austin", logo: SelfEmployedLogo },
    year: "2023",
    link: "",
    description:
      "I crafted, developed, and launched my very own wedding website, serving as a central hub for guests to access crucial information about the ceremony, reception, accommodations, gifts, and RSVP process. The website boasts full responsiveness, ensuring seamless navigation across various mobile devices.\nTo safeguard privacy, the website requires a password for access. Leveraging NextJS, I harnessed server-side functionality to execute JavaScript code, enabling dynamic features such as the secure RSVP form, which securely transmits responses to a designated email account via an API.\nThis project presented an excellent opportunity for me to sharpen my skills, particularly in NextJS, as well as the latest versions of React, Redux, and JavaScript. Deployed and managed through Vercel, the website benefitted from a swift feedback loop during development and testing, thanks to seamless CI/CD integration.",
    technologies: [
      { name: "NextJS", logo: NextJSLogo },
      { name: "React", logo: ReactLogo },
      { name: "Redux", logo: ReduxLogo },
      { name: "Javascript", logo: JavaScriptLogo },
      { name: "HTML5", logo: HTML5Logo },
      { name: "CSS", logo: CSSLogo },
      { name: "Vercel", logo: VercelLogo },
    ],
  },
  {
    name: "Analytics & Reporting - New Yelp Microservice",
    company: { name: "Uberall", logo: UberallLogo },
    employer: { name: "Uberall", logo: UberallLogo },
    year: "October 2023 - Present",
    link: "https://uberall.com/en-gb/platform-overview",
    description:
      "I'm currently spearheading the design, architecture, and implementation of a new Yelp API Microservice. This initiative aims to decouple critical functionality from the Uberall Monolith, ensuring code maintainability, performance, and independence from other areas of functionality.\nTo achieve this, I've crafted a new architecture, complete with detailed diagrams and extensive documentation. Moving away from the Grails Monolith, the architecture will leverage technologies such as Kotlin, Micronaut, AWS, Kubernetes, and PostgreSQL.\nThis endeavor has been a journey of learning and adaptation, involving the assessment of current implementations and identification of key areas for improvement. I've shared my insights with the team through presentations and comprehensive documentation.\nFollowing the presentation of the architecture proposal to the engineering team, I'm pleased to report that they were impressed with the design and have greenlit its implementation. The rollout of the new microservice is scheduled for Spring 2024, adhering to a structured framework comprising epics, tickets, and thorough documentation that I've provided for the engineering team to follow.",
    technologies: [
      { name: "Kotlin", logo: KotlinLogo },
      { name: "Micronaut", logo: MicronautLogo },
      { name: "PostgreSQL", logo: PostgreSQLLogo },
      { name: "Kubernetes", logo: KubernetesLogo },
      { name: "AWS", logo: AWSLogo },
      { name: "LucidChart", logo: LucidChartLogo },
      { name: "PSM1", logo: PSMLogo },
      { name: "Atlassian", logo: AtlassianLogo },
    ],
  },
  {
    name: "Analytics & Reporting - Agile Development",
    company: { name: "Uberall", logo: UberallLogo },
    employer: { name: "Uberall", logo: UberallLogo },
    year: "October 2023 - Present",
    link: "https://uberall.com/en-gb/platform-overview",
    description:
      "I've assumed a leadership role in advocating for agile development practices and streamlining processes within the Analytics & Reporting team. Since joining, I've been instrumental in fostering agile discussions and facilitating knowledge sharing to bolster our team's agile proficiency. This effort has included defining criteria for readiness and completion, as well as crafting standardised ticket templates.\nI maintain a vigilant stance on ensuring adherence to established processes and am proactive in addressing any deviations from our agreed-upon procedures. By nurturing a culture of agility and continuous improvement, I strive to propel our team towards greater efficiency and effectiveness in delivering high-quality results.",
    technologies: [
      { name: "Scrum", logo: ScrumLogo },
      { name: "PSM1", logo: PSMLogo },
      { name: "Atlassian", logo: AtlassianLogo },
    ],
  },
  {
    name: "Analytics & Reporting - Uberall CoreX",
    company: { name: "Uberall", logo: UberallLogo },
    employer: { name: "Uberall", logo: UberallLogo },
    year: "April 2023 - Present",
    link: "https://uberall.com/en-gb/platform-overview",
    description:
      "In my capacity as the primary Backend Engineer within the Analytics & Reporting team, I assume a pivotal role focused on the Uberall Monolith. This role encompasses a diverse array of responsibilities, ranging from promptly addressing critical bug fixes for our esteemed clients to enhancing the overall quality of our existing codebase.\nMy efforts are directed towards improving code readability, maintainability, and performance through strategic refactoring initiatives. Furthermore, I am deeply engaged in the introduction of innovative features, leveraging advanced methodologies such as multi-threaded parallel computing, distributed caching, and Elasticsearch indexing.\nBy consistently adopting best practices and exploring cutting-edge technologies, I aim to ensure that our platform remains at the forefront of industry standards, delivering optimal performance and functionality to our user base.",
    technologies: [
      { name: "Grails", logo: GrailsLogo },
      { name: "Elasticsearch", logo: ElasticsearchLogo },
      { name: "Redis", logo: RedisLogo },
      { name: "SQL", logo: SQLLogo },
      { name: "AWSLogo", logo: AWSLogo },
      { name: "JUnit 5", logo: JUnit5Logo },
    ],
  },
  {
    name: "Analytics & Reporting - Facebook API Integration",
    company: { name: "Uberall", logo: UberallLogo },
    employer: { name: "Uberall", logo: UberallLogo },
    year: "April 2023 - November 2023",
    link: "https://uberall.com/en-gb/platform-overview",
    description:
      "I was fully committed to expanding the capabilities of the Analytics & Reporting team by integrating with Facebook APIs. Taking the helm, I spearheaded the integration with Facebook's Graph API, leading to notable enhancements in data visualisations, charting, and insights accessible to Uberall platform clients via Periscope dashboards.\nThis initiative demanded extensive research and development, for which I took complete ownership. The R&D phase involved meticulous study of the Facebook Graph API documentation, strategic planning for translating raw data into customer-friendly visualisations, and thorough documentation creation for both Uberall engineers and customer support teams. This comprehensive approach ensured seamless integration and optimised utilisation of Facebook data for enhanced analytics and reporting functionalities.",
    technologies: [
      { name: "Grails", logo: GrailsLogo },
      { name: "JUnit 5", logo: JUnit5Logo },
      { name: "SQL", logo: SQLLogo },
      { name: "PostgreSQL", logo: PostgreSQLLogo },
      { name: "Periscope", logo: PeriscopeLogo },
      { name: "Mockito", logo: MockitoLogo },
      { name: "Docker", logo: DockerLogo },
    ],
  },
  {
    name: "Engagement - Uberall CoreX",
    company: { name: "Uberall", logo: UberallLogo },
    employer: { name: "Uberall", logo: UberallLogo },
    year: "March 2023 - April 2023",
    link: "https://uberall.com/en-gb/platform-overview",
    description:
      "I contributed to the enhancement of customer experience and engagement for the Uberall SaaS application by developing new microservices. This entailed identifying key functionalities within the legacy code that could be extracted into microservices, thereby improving the platform's maintainability, readability, and scalability.\nThe process of creating these microservices involved thorough comprehension and seamless integration with APIs from various platforms such as Instagram, Facebook, Twitter, and Google. This integration not only expanded the application's capabilities but also enriched the overall user experience by harnessing the power of external data sources.",
    technologies: [
      { name: "Kotlin", logo: KotlinLogo },
      { name: "Micronaut", logo: MicronautLogo },
      { name: "Grails", logo: GrailsLogo },
      { name: "JUnit 5", logo: JUnit5Logo },
      { name: "Docker", logo: DockerLogo },
      { name: "AWS", logo: AWSLogo },
    ],
  },
  {
    name: "Front End Mobilisation",
    company: { name: "Sky Betting & Gaming", logo: SBGLogo },
    employer: { name: "Sky Betting & Gaming", logo: SBGLogo },
    year: "October 2022 - February 2023",
    link: "https://m.skybet.com/",
    description:
      "I contributed to the initial phase of the project, focused on revamping the company's website into a mobile-first application from scratch. This endeavor involved creating several Proof of Concepts (PoCs) using the proposed technology stack (React, React Native, NextJS, Storybook, Jest, and Cypress) to validate the feasibility of the rebuild. A streamlined approach was established to validate an end-to-end user journey on the PoC, which was subsequently deployed successfully to AWS.\nIn addition to developing the PoCs, I played a key role in defining coding standards, implementing best testing practices, and establishing a design system based on the atomic design methodology. These efforts were aimed at ensuring consistency, scalability, and maintainability throughout the development process.",
    technologies: [
      { name: "React", logo: ReactLogo },
      { name: "NextJs", logo: NextJsLogo },
      { name: "React Native", logo: ReactNativeLogo },
      { name: "Storybook", logo: StorybookLogo },
      { name: "Jest", logo: JestLogo },
      { name: "Cypress", logo: CypressLogo },
    ],
  },
  {
    name: "Data Supplier Integration for Multiple Sports",
    company: { name: "Sky Betting & Gaming", logo: SBGLogo },
    employer: { name: "Sky Betting & Gaming", logo: SBGLogo },
    year: "2021 - October 2022",
    link: "https://m.skybet.com/",
    description:
      "I collaborated on integrating with a new data supplier to deliver real-time data across multiple pre-live and in-play sports categories such as Greyhounds, Tennis, and NFL. This endeavor required cross-team collaboration to develop scalable applications capable of consuming and transforming raw data from the new supplier. Setting up new environments within the client's infrastructure was essential for this integration, involving extensive work with AWS, Kubernetes, Docker, and Jenkins.\nThe integration with the new supplier necessitated both the creation of new applications from scratch and the seamless integration with existing applications in the client's architecture. Given the high volume of traffic from the new data supplier, prioritising the scalability and maintainability of these applications was paramount. To achieve this, I implemented parallelisation techniques, leveraging multiple CPU threads, and optimised resource allocation in Kubernetes to minimise latencies and ensure optimal performance.",
    technologies: [
      { name: "Kotlin", logo: KotlinLogo },
      { name: "Spring Boot", logo: SpringBootLogo },
      { name: "RabbitMQ", logo: RabbitMQLogo },
      { name: "Kafka", logo: KafkaLogo },
      { name: "Grafana", logo: GrafanaLogo },
      { name: "Prometheus", logo: PrometheusLogo },
      { name: "AWS", logo: AWSLogo },
      { name: "Docker", logo: DockerLogo },
      { name: "Kubernetes", logo: KubernetesLogo },
      { name: "Jenkins", logo: JenkinsLogo },
      { name: "Gradle", logo: GradleLogo },
    ],
  },
  {
    name: "Enhancing Customer Experience",
    company: { name: "Sky Betting & Gaming", logo: SBGLogo },
    employer: { name: "Sky Betting & Gaming", logo: SBGLogo },
    year: "2021",
    link: "https://m.skybet.com/",
    description:
      "I was actively involved in enhancing customer experience across football betting markets by refining existing applications and architecture. This involved implementing modifications to existing applications to enrich the data flow downstream via Kafka and AWS SQS. Additionally, I enhanced availability by introducing new functionalities to markets and incorporating necessary logic for various event scenarios.\nTo ensure seamless deployment and management, I containerised the applications using Docker and orchestrated them with Kubernetes. Leveraging AWS services, the applications were deployed and managed through Jenkins. Deploying to AWS provided access to a wide array of tools, including AWS CloudWatch, facilitating efficient debugging and optimisation throughout the development process.",
    technologies: [
      { name: "Kotlin", logo: KotlinLogo },
      { name: "AWS", logo: AWSLogo },
      { name: "Docker", logo: DockerLogo },
      { name: "Kubernetes", logo: KubernetesLogo },
      { name: "Jenkins", logo: JenkinsLogo },
      { name: "Gradle", logo: GradleLogo },
    ],
  },
  {
    name: "Data Supplier Integration",
    company: { name: "Sky Betting & Gaming", logo: SBGLogo },
    employer: { name: "Sky Betting & Gaming", logo: SBGLogo },
    year: "2020",
    link: "https://m.skybet.com/",
    description:
      "I led the initiative to replace an existing service responsible for streaming and formatting live data from greyhound and horse racing events for internal business systems. Using Kotlin and Spring Boot, I developed a series of applications to transform and publish the data seamlessly. Kafka was utilised for data transmission and consumption, ensuring reliable connectivity and scalability. I also employed Docker and Kubernetes for efficient containerisation and orchestration of the applications.\nTo provide stakeholders and developers with comprehensive insights into application performance and efficiency, I integrated Grafana and Prometheus. These tools enabled the creation of intuitive data visualisations, robust monitoring capabilities, and proactive alerting mechanisms, ensuring optimal system health and operational excellence.",
    technologies: [
      { name: "Kotlin", logo: KotlinLogo },
      { name: "Spring Boot", logo: SpringBootLogo },
      { name: "Kafka", logo: KafkaLogo },
      { name: "Docker", logo: DockerLogo },
      { name: "Kubernetes", logo: KubernetesLogo },
      { name: "Apache Maven", logo: MavenLogo },
    ],
  },
  {
    name: "New Web Build",
    company: { name: "N Brown Group PLC", logo: NBrownLogo },
    employer: { name: "AND Digital", logo: ANDLogo },
    link: "https://www.nbrown.co.uk/",
    year: "2020",
    description:
      "N Brown Group plc, headquartered in Manchester, England, stands as a prominent online retailer renowned for its diverse product range, specialising in clothing, footwear, and homewares, with a particular focus on underserved customer segments.\nDuring my tenure as part of the client's development team, I led the implementation of a robust testing strategy aimed at streamlining the development and release processes. Central to this strategy was the integration of Mock Service Worker into the project, enabling the simulation of API calls to external services. This approach not only facilitated the decoupling of the web application from external services in both development and testing environments but also eliminated dependence on these services in the event of downtime.\nFurthermore, I orchestrated the integration of Cypress, a powerful JavaScript End-to-End (E2E) automation testing framework, into the project. Cypress, operational both locally and as part of a CI Pipeline, enables comprehensive testing of application flows. Its intuitive interface allows developers to visually track the flow in real-time, facilitating efficient debugging and ensuring seamless performance.",
    technologies: [
      { name: "React", logo: ReactLogo },
      { name: "HTML5", logo: HTML5Logo },
      { name: "MockServiceWorker", logo: MSWLogo },
      { name: "Cypress", logo: CypressLogo },
    ],
  },
  {
    name: "Portfolio Web Application",
    company: { name: "Mark Austin", logo: SelfEmployedLogo },
    employer: { name: "Mark Austin", logo: SelfEmployedLogo },
    year: "2020",
    link: "https://m-austin.co.uk/",
    description:
      "I conceptualised, crafted, and launched this dynamic single-page application to showcase my technical prowess, highlight my projects, and demonstrate my extensive industry experience. The front end was meticulously developed using React, HTML5, and CSS, ensuring a sleek and intuitive user interface. Meanwhile, the back end was powered by Node.js in conjunction with the Express.js framework. Deployment was seamlessly handled with Google Firebase for the client and Render for the server.\nThe architecture of the site was thoughtfully designed to prioritise the reusability of React components, aligning with industry best practices and facilitating effortless modifications and expansions. Content is dynamically populated via JavaScript files housing arrays of data for each section, offering unparalleled versatility and the potential for the site to serve as a comprehensive portfolio template for fellow professionals.",
    technologies: [
      { name: "React", logo: ReactLogo },
      { name: "HTML5", logo: HTML5Logo },
      { name: "CSS", logo: CSSLogo },
      { name: "Node JS", logo: NodeLogo },
    ],
  },
  {
    name: "Progessive Web Application",
    company: { name: "MKM Building Supplies", logo: MKMLogo },
    employer: { name: "AND Digital", logo: ANDLogo },
    year: "2020",
    link: "https://www.mkmbs.co.uk/",
    description:
      "MKM Building Supplies stands as one of the UK’s premier building merchants, offering an extensive range of building, plumbing, and timber supplies.\nI played a pivotal role in a collaborative effort to revamp the outdated and non-responsive MKM website. Utilising React and a third-party Front End as a Service, we developed a new front end, featuring reusable components to enhance user experience, especially on mobile devices. Content population was streamlined through scraping from a donor site, eliminating the need for extensive backend reconstruction. The maintenance of site state and user details was seamlessly managed through Redux.\nIn addition, I contributed to the integration of a cutting-edge CMS, Amplience, empowering users to dynamically create and modify site content without any coding expertise.\nEnsuring top-notch code quality and functionality, I led the implementation of robust testing frameworks. Leveraging Jest for unit tests and Nightwatch for E2E Automation tests, we ensured a reliable and polished end product.",
    technologies: [
      { name: "React", logo: ReactLogo },
      { name: "HTML5", logo: HTML5Logo },
      { name: "CSS", logo: CSSLogo },
      { name: "Amplience", logo: AmplienceLogo },
    ],
  },

  {
    name: "Responsive Website",
    company: { name: "Queensgate Glass", logo: QueensgateGlassLogo },
    employer: { name: "Mark Austin", logo: SelfEmployedLogo },
    year: "2019",
    link: "https://queensgateglass.co.uk/",
    description:
      "Queensgate Glass Ltd is an independent company located in Stockport, South Manchester, specialising in the design, supply, and installation of a comprehensive range of colored and mirrored glass splashbacks.\n I led the design, implementation, and deployment of a new responsive website, empowering the business owners to effortlessly update site content and make minor design adjustments without the need for technical expertise or programming skills. The website boasts a sizable gallery, frequently updated by the owners to showcase their latest projects, alongside a seamlessly integrated contact form linked to Gmail. Leveraging WordPress, HTML5, and CSS, the website was meticulously crafted to ensure optimal functionality and user experience.",
    technologies: [
      { name: "Wordpress", logo: WordpressLogo },
      { name: "HTML5", logo: HTML5Logo },
      { name: "CSS", logo: CSSLogo },
      { name: "MySQL", logo: MySQLLogo },
    ],
  },
  {
    name: "Updated Architecture",
    company: { name: "KCOM", logo: KCOMLogo },
    employer: { name: "AND Digital", logo: ANDLogo },
    year: "2019",
    link: "https://www.kcomhome.com/",
    description:
      "At KCOM Group, a leading communications and IT services provider in the UK, I took charge of developing a Proof of Concept (POC) aimed at showcasing the potential benefits of upgrading outdated architecture. Utilising Java, Spring Boot, Apache Maven, and Docker, I crafted a robust solution integrating SOAP messaging protocol and REST API functionalities. This enabled seamless data transfer across multiple services, enhancing system efficiency and user experience.\nFollowing the successful completion of the POC, I transitioned to integrating product catalogue changes into the existing system architecture. Leveraging Java, JMS (ActiveMQ), and RabbitMQ, I facilitated smooth data transfer between systems, ensuring effective communication and synchronisation.\nThroughout these projects, I emphasised the importance of containerisation using Docker, ensuring that all developed applications were efficiently packaged and ready for deployment, thereby optimising scalability and resource utilisation.",
    technologies: [
      { name: "Java", logo: JavaLogo },
      { name: "SpringBoot", logo: SpringbootLogo },
      { name: "Docker", logo: DockerLogo },
      { name: "Apache Maven", logo: MavenLogo },
    ],
  },
  {
    name: "Responsive Website",
    company: { name: "The Mens Room", logo: TheMensRoomLogo },
    employer: { name: "AND Digital", logo: ANDLogo },
    year: "2019",
    link: "https://mroom.co.uk/",
    description:
      "The Mens Room is a compassionate arts & health charity situated in Manchester, dedicated to providing support to men and trans individuals who are sex workers and/or experiencing homelessness.\nIn collaboration with The Mens Room, I undertook the design, implementation, and deployment of a new responsive website, tailored to empower the charity to effortlessly update their online presence without requiring any technical expertise. The website was meticulously crafted using WordPress, HTML5, and CSS, ensuring both functionality and aesthetic appeal.\nNotable features of the website include a live Twitter feed, enabling real-time updates and communication with stakeholders, a user-friendly contact form facilitating seamless interaction with the charity, and PayPal integration for streamlined donation processing. These features collectively enhance the accessibility and effectiveness of The Mens Room's online platform, enabling them to further their vital mission of providing support and assistance to vulnerable individuals within the community.",
    technologies: [
      { name: "Wordpress", logo: WordpressLogo },
      { name: "HTML5", logo: HTML5Logo },
      { name: "CSS", logo: CSSLogo },
      { name: "MySQL", logo: MySQLLogo },
    ],
  },
  {
    name: "Personalised interactive IoT experiences for Museums",
    company: { name: "Sheffield Hallam University", logo: HallamLogo },
    employer: { name: "Mark Austin", logo: SelfEmployedLogo },
    year: "2018",
    link: "https://www.shu.ac.uk/",
    description:
      "For my final year project, I collaborated with Kelham Island in Sheffield to introduce digital interactive exhibits aimed at offering visitors a personalised museum experience.\nCentral to this project was the utilisation of the Quando toolset, developed by Andrew Stratton, as a foundational framework. Quando, a visual programming toolset, empowers Cultural Heritage Practitioners (CHPs) to craft interactive exhibits and integrate IoT technologies without delving into the intricacies of coding or technical implementation details.\nExpanding upon the Quando toolset, I employed JavaScript and Python to introduce enhancements tailored to visitor identification, detection, and personalisation capabilities. These extensions were designed to be accessible and comprehensible to CHPs, streamlining the implementation process.\nA key aspect of the project involved leveraging BBC Micro: bit microcontrollers as the primary hardware for visitor interaction with the exhibits. These microcontrollers facilitated seamless engagement with the digital displays, enriching the visitor experience.\nTo manage and store personal visitor interactions effectively, I implemented MongoDB as the database solution. Through Bluetooth connectivity with the BBC Micro: bit controllers, visitor interactions were logged and stored in MongoDB, enabling comprehensive tracking and analysis of visitor engagement during each museum visit.",
    technologies: [
      { name: "JavaScript", logo: JavaScriptLogo },
      { name: "Node.JS", logo: NodeLogo },
      { name: "Python", logo: PythonLogo },
      { name: "MongoDB", logo: MongoLogo },
      { name: "BBC Micro:bit", logo: MicrobitLogo },
    ],
  },
  {
    name: "Data Analytics & Visualisations",
    company: { name: "Baker Hughes, a GE Company", logo: BakerHughesLogo },
    employer: { name: "Baker Hughes, a GE Company", logo: BakerHughesLogo },
    year: "2017",
    link: "https://www.bakerhughes.com/",
    description:
      "Baker Hughes is an American international industrial service company and one of the world's largest oil field services companies. \n A data analytics project that transformed big data from the factory machinery into complex data visualisations in real time to showcase the performance of each machine and where improvements could be made. This helped to increase efficiency and production across the factory. The visualisations were hosted on large monitors around the factory as well as on an online landing page that was used by GE leaders on a daily basis. The project involved interacting with large data lakes and extracting all of the relevant information. I used Tableau to visualise the data and custom SQL queries to extract the data.",
    technologies: [
      { name: "Tableau", logo: TableauLogo },
      { name: "Oracle SQL", logo: OracleSQL },
    ],
  },
];

export default projects;
