import React from "react";
import PropTypes from "prop-types";
import "./index.css";

import { Link } from "react-scroll";
import UpIcon from "../../icons/arrow-up.svg";

const Footer = ({ isMobile, profile }) => {
  return (
    <div className={isMobile ? "footer-container-mobile" : "footer-container"}>
      <Link
        className="up-icon-container"
        activeClass="active"
        to="home"
        spy={true}
        smooth={true}
        duration={500}
      >
        <img
          className="up-icon"
          src={UpIcon}
          alt="return to top of page up icon"
        />
      </Link>
      <div className="footer-social-media-container">
        {profile.socialMedia.map((x, key) => (
          <a key={key} href={x.link} target="_blank" rel="noopener noreferrer">
            <img className="footer-icon" src={x.icon} alt={x.name} />
          </a>
        ))}
      </div>
      <span className="copyright-text">
        © Copyright {new Date().getFullYear()} {profile.name}
      </span>
    </div>
  );
};

export default Footer;

Footer.propTypes = {
  isMobile: PropTypes.bool,
  profile: PropTypes.shape({
    name: PropTypes.string,
    heading: PropTypes.string,
    photo: PropTypes.string,
    bitmoji: PropTypes.string,
    intro: PropTypes.string,
    description: PropTypes.string,
    socialMedia: PropTypes.array,
    background: PropTypes.string,
  }),
};
