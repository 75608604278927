import React, { useState } from "react";
// import CookieConsent from "react-cookie-consent";
import { useMediaQuery } from "react-responsive";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "./App.css";
import { DefaultSeo } from "next-seo";

import Footer from "./components/Footer";

import Home from "./pages/home";
import Skills from "./pages/skills";
import Career from "./pages/career";
import Projects from "./pages/projects";
import Contact from "./pages/contact";

import profileData from "./data/profile";
import skillsData from "./data/skills";
import experienceData from "./data/experience";
import projectsData from "./data/projects";
import awardsData from "./data/awards";
import volunteeringData from "./data/volunteering";

const App = () => {
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isMonitor = useMediaQuery({ minWidth: 1550 });
  const isMobile = !isDesktop && !isMonitor ? true : false;

  const [lockedState, setLockedState] = useState(false);

  const SEO = {
    title: `${profileData.name} | ${profileData.title}`,
    description: `${profileData.name} is a ${profileData.title} from ${profileData.country} who currently works at ${profileData.currentCompany}.`,
    openGraph: {
      type: "website",
      url: profileData.website,
      title: `${profileData.name} | ${profileData.title}`,
      description: `${profileData.name} is a ${profileData.title} from ${profileData.country} who currently works at ${profileData.currentCompany}.`,
      images: [
        {
          url: profileData.image,
          alt: `${profileData.name} - ${profileData.title}`,
        },
      ],
      site_name: "www.m-austin.co.uk",
    },
    twitter: {
      handle: "@handle", // Add your Twitter handle here
      site: "@site", // Add your Twitter site here
      cardType: "summary_large_image",
    },
  };

  return (
    <HelmetProvider>
      <div className={lockedState ? "app-locked" : "app"}>
        <Helmet>
          <meta charSet="utf-8" />
          <link rel="canonical" href={profileData.website} />
        </Helmet>
        <DefaultSeo {...SEO} />

        <section id="home">
          <Home
            isMonitor={isMonitor}
            isDesktop={isDesktop}
            profile={profileData}
            preventScroll={setLockedState}
          />
        </section>
        <section id="about">
          <Skills
            isMonitor={isMonitor}
            isDesktop={isDesktop}
            skillsList={skillsData}
          />
        </section>
        <section id="career">
          <Career
            isMonitor={isMonitor}
            isDesktop={isDesktop}
            isMobile={isMobile}
            experienceList={experienceData}
          />
        </section>
        <section id="projects">
          <Projects
            isMonitor={isMonitor}
            isDesktop={isDesktop}
            isMobile={isMobile}
            projectsList={projectsData}
            heading="Projects"
          />
        </section>
        <section id="awards">
          <Projects
            isMonitor={isMonitor}
            isDesktop={isDesktop}
            isMobile={isMobile}
            projectsList={awardsData}
            heading="Awards"
          />
        </section>
        <section id="volunteering">
          <Projects
            isMonitor={isMonitor}
            isDesktop={isDesktop}
            isMobile={isMobile}
            projectsList={volunteeringData}
            heading="Volunteering"
          />
        </section>
        <section id="contact">
          <Contact
            isMonitor={isMonitor}
            isDesktop={isDesktop}
            isMobile={isMobile}
          />
        </section>
        <section id="footer">
          <Footer
            isMonitor={isMonitor}
            isDesktop={isDesktop}
            isMobile={isMobile}
            profile={profileData}
          />
        </section>
        {/* <CookieConsent
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          cookieName="cookie-consent"
          flipButtons={true}
          enableDeclineButton={true}
          setDeclineCookie={true}
          style={{ background: "#2B373B", display: "flex" }}
          buttonWrapperClasses={"cookie-buttons"}
          buttonStyle={{
            color: "#4e503b",
            background: "#ffffff",
            fontSize: "14px",
            borderRadius: "10px",
          }}
          declineButtonStyle={{
            color: "#4e503b",
            background: "#ffffff",
            fontSize: "14px",
            borderRadius: "10px",
          }}
          expires={150}
        >
          This website uses cookies for Google Analytics. {"\n"} I agree to the
          setting of the cookies:
        </CookieConsent> */}
      </div>
    </HelmetProvider>
  );
};

export default App;
