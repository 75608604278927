import React from "react";
import PropTypes from "prop-types";
import "./index.css";

import ContainerOverlay from "../../components/ContainerOverlay";
import DesktopNav from "../../components/DesktopNav";
import MobileNav from "../../components/MobileNav";
import BackgroundImage from "../../components/BackgroundImage";
import Bio from "../../components/Biography";

const Home = ({ isMonitor, isDesktop, isMobile, profile, preventScroll }) => {
  let menuState = false;
  return (
    <div className="home">
      {isDesktop ? (
        <DesktopNav isMonitor={isMonitor} />
      ) : (
        <MobileNav
          socialMedia={profile.socialMedia}
          menuState={menuState}
          preventScroll={preventScroll}
        />
      )}
      <BackgroundImage
        isMonitor={isMonitor}
        isDesktop={isDesktop}
        isMobile={isMobile}
        img={profile.background}
      />
      <ContainerOverlay
        isDesktop={isDesktop}
        isMonitor={isMonitor}
        child={
          <Bio isDesktop={isDesktop} isMonitor={isMonitor} profile={profile} />
        }
      />
    </div>
  );
};

export default Home;

Home.propTypes = {
  isMonitor: PropTypes.bool,
  isDesktop: PropTypes.bool,
  isMobile: PropTypes.bool,
  profile: PropTypes.shape({
    name: PropTypes.string,
    heading: PropTypes.string,
    photo: PropTypes.string,
    bitmoji: PropTypes.string,
    intro: PropTypes.string,
    description: PropTypes.string,
    socialMedia: PropTypes.array,
    background: PropTypes.string,
  }),
  preventScroll: PropTypes.func,
};
