import React from "react";
import PropTypes from "prop-types";
import "./index.css";

const CareerLogo = ({ logo, alt, url, isMobile }) => {
  const containerClass = isMobile ? "logo-container-mobile" : "logo-container";
  return (
    <div className={containerClass}>
      <a href={url} rel="noopener noreferrer" target="_blank">
        <img className="career-logo" src={logo} alt={alt} />
      </a>
    </div>
  );
};

export default CareerLogo;

CareerLogo.propTypes = {
  logo: PropTypes.string,
  alt: PropTypes.string,
  isMobile: PropTypes.bool,
};
