import React from "react";
import PropTypes from "prop-types";
import "./index.css";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

const SkillsGrid = ({ skills, isDesktop, isMonitor }) => {
  const logoClass = isMonitor
    ? "logo-large"
    : isDesktop
    ? "logo-desktop"
    : "logo-mobile";
  const skillsList = skills.map((x, key) => (
    <Grid key={key} id="grid-item" item xs={4}>
      <Paper id="skills-item">
        <img className={logoClass} src={x.logo} alt={x.name} />
      </Paper>
    </Grid>
  ));

  return (
    <div className="grid-container">
      <Grid id="grid" container spacing={1}>
        <Grid container item xs={12} spacing={1}>
          {skillsList}
        </Grid>
      </Grid>
    </div>
  );
};

export default SkillsGrid;

SkillsGrid.propTypes = {
  skills: PropTypes.array,
  isMonitor: PropTypes.bool,
  isDesktop: PropTypes.bool,
};
