import React from "react";
import PropTypes from "prop-types";
import "./index.css";

import { Link } from "react-scroll";
import NavLink from "../NavLink";

const DesktopNav = ({ isMonitor }) => {
  return (
    <div className="nav-container">
      <div className="nav-contents">
        <Link
          className="anchor-link"
          activeClass="active"
          to="about"
          spy={true}
          smooth={true}
          duration={500}
        >
          <NavLink title="About" isMonitor={isMonitor} />
        </Link>
        <Link
          className="anchor-link"
          activeClass="active"
          to="career"
          spy={true}
          smooth={true}
          duration={500}
        >
          <NavLink title="Career" isMonitor={isMonitor} />
        </Link>
        <Link
          className="anchor-link"
          activeClass="active"
          to="projects"
          spy={true}
          smooth={true}
          duration={500}
        >
          <NavLink title="Projects" isMonitor={isMonitor} />
        </Link>
        <Link
          className="anchor-link"
          activeClass="active"
          to="awards"
          spy={true}
          smooth={true}
          duration={500}
        >
          <NavLink title="Awards" isMonitor={isMonitor} />
        </Link>
        <Link
          className="anchor-link"
          activeClass="active"
          to="volunteering"
          spy={true}
          smooth={true}
          duration={500}
        >
          <NavLink title="Volunteering" isMonitor={isMonitor} />
        </Link>
        <Link
          className="anchor-link"
          activeClass="active"
          to="contact"
          spy={true}
          smooth={true}
          duration={500}
        >
          <NavLink title="Contact" isMonitor={isMonitor} />
        </Link>
      </div>
    </div>
  );
};

export default DesktopNav;

DesktopNav.propTypes = {
  isMonitor: PropTypes.bool,
};
