import LinkedInIcon from "../icons/linkedin.svg";
import GithubIcon from "../icons/github.svg";
import ProfilePhoto from "../img/profile/november_2023.webp";
import backgroundImage from "../img/home/vik-iceland-unsplash.webp";
import BitmojiIcon from "../img/profile/bitmoji.svg";

const profile = {
  name: "Mark Austin",
  heading: "Hi, I'm Mark.",
  photo: ProfilePhoto,
  bitmoji: BitmojiIcon,
  intro: "I'm a Senior software Engineer from the UK.",
  description: "I created this site using React and Node.js.",
  socialMedia: [
    {
      name: "LinkedIn",
      link: "https://www.linkedin.com/in/mark-a-austin/",
      icon: LinkedInIcon,
    },
    {
      name: "Github",
      link: "https://github.com/markaustindev",
      icon: GithubIcon,
    },
  ],
  background: backgroundImage,
};

export default profile;
