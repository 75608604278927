import React from "react";
import PropTypes from "prop-types";

import "./index.css";

import Collapsible from "react-collapsible";
import ExpandIcon from "../../icons/expand.svg";
import CollapseIcon from "../../icons/collapse.svg";
import ArrowUpIcon from "../../icons/arrow-up.svg";
import ArrowDownIcon from "../../icons/arrow-down.svg";
import ArrowLeftIcon from "../../icons/arrow-left.svg";
import ArrowRightIcon from "../../icons/arrow-right.svg";

const CollapsibleContainer = ({ content, openIcon, closeIcon }) => {
  const selectIcon = (interaction) => {
    switch (interaction) {
      case "up":
        return ArrowUpIcon;
      case "down":
        return ArrowDownIcon;
      case "left":
        return ArrowLeftIcon;
      case "right":
        return ArrowRightIcon;
      case "expand":
        return ExpandIcon;
      case "collapse":
        return CollapseIcon;
      default:
        return ExpandIcon;
    }
  };
  return (
    <Collapsible
      trigger={
        <img
          className="collapsible-icon"
          src={selectIcon(openIcon)}
          alt="arrow"
        />
      }
      triggerWhenOpen={
        <img
          className="collapsible-icon"
          src={selectIcon(closeIcon)}
          alt="arrow"
        />
      }
    >
      {content}
    </Collapsible>
  );
};

export default CollapsibleContainer;

CollapsibleContainer.propTypes = {
  content: PropTypes.object,
  openIcon: PropTypes.string,
  closeIcon: PropTypes.string,
};
